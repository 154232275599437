$(document).ready(function(){
    //enable tooltip
    $('[data-toggle="tooltip"]').tooltip();

    //enable popover
    $('[data-toggle="popover"]').popover();

    //sticky navigation
    $(".navbar").sticky({ topSpacing: 0 });

    //Off Canvas
    $('.openOffCanvas').on('click', function () {
        $('.offcanvas').addClass('open');
        // $('#main').addClass('canvas-open');
        $('.site-overlay').css('visibility', 'visible');
        $('html, body').css('overflow', 'hidden');
        $('.openOffCanvas').addClass('canvas-show');
    });

    $('.site-overlay').on('click',function (e) {
        var offcanvas = $('.offcanvas');

        if (offcanvas.has(e.target).length === 0 && $('.openOffCanvas').has(e.target).length === 0) {
            offcanvas.removeClass('open');
            // $('#main').removeClass('canvas-open');
            $('.site-overlay').css('visibility', 'hidden');
            $('html, body').css('overflow', 'auto');
            $('.openOffCanvas').removeClass('canvas-show');
        }
    });

    //back to top
    $('body').append('<a href="javascript:void(0);" class="box-shadow-6" id="back-to-top"><i class="flaticon-arrows-4"></i> <i class="flaticon-arrows-4 hover"></i></a>');

    $(window).scroll(function() {
        if ($(this).scrollTop() >= 200) {
            $('#back-to-top').fadeIn(200);
        } else {
            $('#back-to-top').fadeOut(200);
        }
    });
    $('#back-to-top').click(function() {
        $('body,html').animate({
            scrollTop : 0
        }, 500);
    });


    //Mega menu
    $(".dropdown-menu").on('click', function(e) {
        e.stopPropagation();
    });

    $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        if (!$(this).parents('.dropdown').first().hasClass('show')) {
            $(this).parents('.dropdown').first().find('.show').removeClass("show");
        }
        var $dropdown = $(this).parents('.dropdown').first(".dropdown");
        var $dropdownMenu = $(this).next(".dropdown-menu");
        $dropdown.toggleClass('show');
        $dropdownMenu.toggleClass('show');

        // $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
        //     $('.dropdown-submenu .show').removeClass("show");
        // });

        return false;
    });

    // navbar search
    $('.open-search').on('click', function () {
        $('.navbar-search').css('visibility', 'visible');
    });
    $('.close-search').on('click', function () {
        $('.navbar-search').css('visibility', 'hidden');
    });

    $('.animated-slider').on('init', function(e, slick) {
        var $firstAnimatingElements = $('div.hero-slide:first-child').find('[data-animation]');
        doAnimations($firstAnimatingElements);
    });
    $('.animated-slider').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
        var $animatingElements = $('div.hero-slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
        doAnimations($animatingElements);
    });

    function doAnimations(elements) {
        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function() {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
                'animation-delay': $animationDelay,
                '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function() {
                $this.removeClass($animationType);
            });
        });
    }
});

$(document).ready(function(){
    // custom-object-fit-cover
    var userAgent, ieReg, ie, edge;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    ie = ieReg.test(userAgent);
    edge = userAgent.indexOf('Edge/');

    if(ie || edge != -1) {
        $(".slider-simple .slider-item").each(function () {
            var imgUrl = $(this).find("img").prop("src");
            if (imgUrl) {
                $(this).css("backgroundImage", 'url(' + imgUrl + ')').addClass("custom-object-fit-cover");
            }
        });
        $(".shop-categories-grid .category").each(function () {
            var imgUrl = $(this).find("img").prop("src");
            if (imgUrl) {
                $(this).css("backgroundImage", 'url(' + imgUrl + ')').addClass("custom-object-fit-cover");
            }
        })
    }
});

//incrise decrease input qty
$('.qty').on('click', function() {
    var $t = $(this),
        $in = $('input[name="'+$t.data('field')+'"]'),
        val = parseInt($in.val()),
        valMax = 100,
        valMin = 1;

    // Check if a number is in the field first
    if(isNaN(val) || val < valMin) {
        // If field value is NOT a number, or
        // if field value is less than minimum,
        // ...set value to 0 and exit function
        $in.val(valMin);
        return false;
    } else if (val > valMax) {
        // If field value exceeds maximum,
        // ...set value to max
        $in.val(valMax);
        return false;
    }

    // Perform increment or decrement logic
    if($t.data('func') == 'plus') {
        if(val < valMax) $in.val(val + 1);
    } else {
        if(val > valMin) $in.val(val - 1);
    }
});